import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { VocabProvider } from '@sainsburys-tech/bolt-vocab-provider'
import { Propbar, ErrorBoundary } from '@sainsburys-tech/bolt'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

import { ScrollPositionProvider } from '../helpers/scrollPositionContext'
import { isArgos, getBoltClass, getBoltTheme, getBoltVocab } from '../helpers/brandHelper'
import * as Styled from './MainLayout.styles'

const boltClass = getBoltClass()
const theme = getBoltTheme()
const vocab = getBoltVocab()

const MainLayout = ({ children, history }) => {
  return (
    <ThemeProvider theme={theme}>
      <VocabProvider vocab={vocab}>
        <ScrollPositionProvider>
          <Styled.AppWrapper data-test='app' className={`bolt-${boltClass}`}>
            {isArgos() && (
              <div>
                <Header history={history} />
              </div>
            )}

            <ErrorBoundary>
              <Styled.PropbarContainer>
                <Propbar brand={process.env.BRAND} />
              </Styled.PropbarContainer>
            </ErrorBoundary>

            <Styled.AppContent id='app-content' className='container'>
              <main>{children}</main>
            </Styled.AppContent>
            <div>
              <Footer brand={process.env.BRAND} />
            </div>
          </Styled.AppWrapper>
        </ScrollPositionProvider>
      </VocabProvider>
    </ThemeProvider>
  )
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  history: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
}

export default MainLayout
